import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Nunito\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\",\"preload\":true,\"display\":\"swap\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager" */ "/app/public/images/footer/discord.svg");
;
import(/* webpackMode: "eager" */ "/app/public/images/footer/logo-footer.svg");
;
import(/* webpackMode: "eager" */ "/app/public/images/footer/x.svg");
;
import(/* webpackMode: "eager" */ "/app/public/images/header/kyupad-logo.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/common/header/desktop-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/common/header/mobile-menu.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/header/wallet-connect.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/toast/sonner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/features/ribbon/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/features/web-vitals.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/contexts/global-store-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/contexts/project-detail-store-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/contexts/session-store-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/contexts/wallet-connect-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.css");
